<template>
    <div class="FollowEcharts" id="FollowEcharts">
        <div class="bscroll" ref="bscroll">
          <div class="bscroll-container">
            <p class="pageTop" v-if="!mobileFlag">
                <span class="signalTitle">
                    {{ $route.query.account + $t('echarts_xtitle') }}

                    <span class="signalTitleTips">
                        {{ $t('echarts_orderTips') }}
                    </span>
                </span>
                <!-- <Language class="languageCard" /> -->
            </p>
            <div class="refreshListBtnDiv">
                    <el-button class="confirmDialogBtn" @click="goBack()" >
                        {{
                            $t("MyEcharts_back")
                        }}</el-button>
            </div>
            <!-- <SignalList class="signaListPosition" /> -->
            <div class="PageCommon">
                <div class=" echarts_closeOrderContent">
                    <div class="echarts_closeOrderLeft echarts_top">
                        <el-select v-if="!mobileFlag" v-model="duationType" placeholder="" class="echartsTimeSheet" @change="getEchartData">
                            <el-option key="0" :label="$t('echarts_time1')" value="0">
                            </el-option>
                            <el-option key="1" :label="$t('echarts_time2')" value="1">
                            </el-option>
                            <el-option key="2" :label="$t('echarts_time3')" value="2">
                            </el-option>
                        </el-select>
                        <el-tabs v-model="activeName" @tab-click="getEchartData">
                            <el-tab-pane :label="$t('MyFollowers_Equity') + ' ' + curDayEquity + ' USD'" name="0">
                                <div slot="label" class="mobileTabTitle" v-if="mobileFlag">
                                    <div class="mobileTabTitleTxt">{{ $t('MyFollowers_Equity') }}</div>
                                    <div class="mobileTabTitleData">{{curDayEquity}}</div>
                                </div>
                                <el-select v-if="mobileFlag" v-model="duationType" placeholder="" class="echartsTimeSheet" @change="getEchartData">
                                    <el-option key="0" :label="$t('echarts_time1')" value="0">
                                    </el-option>
                                    <el-option key="1" :label="$t('echarts_time2')" value="1">
                                    </el-option>
                                    <el-option key="2" :label="$t('echarts_time3')" value="2">
                                    </el-option>
                                </el-select>
                                <div class="EquityEchart" ref="EquityEchart" :style="{ width: '100%', height: '320px' }"></div>
                            </el-tab-pane>
                            <el-tab-pane :label="$t('MyFollowers_Lot1') + ' ' + curDayVolume + ' Lots'" name="1">
                                <div slot="label" class="mobileTabTitle" v-if="mobileFlag">
                                    <div class="mobileTabTitleTxt">{{ $t('MyFollowers_Lot1') }}</div>
                                    <div class="mobileTabTitleData">{{curDayVolume}}</div>
                                </div>
                                <el-select v-if="mobileFlag" v-model="duationType" placeholder="" class="echartsTimeSheet" @change="getEchartData">
                                    <el-option key="0" :label="$t('echarts_time1')" value="0">
                                    </el-option>
                                    <el-option key="1" :label="$t('echarts_time2')" value="1">
                                    </el-option>
                                    <el-option key="2" :label="$t('echarts_time3')" value="2">
                                    </el-option>
                                </el-select>
                                <div class="EquityEchart" ref="LotEchart" :style="{ width: '100%', height: '300px' }"></div>
                            </el-tab-pane>
                            <el-tab-pane :label="$t('MyFollowers_PL1') + ' ' + curDayPorfit + ' USD'" name="2">
                                <div slot="label" class="mobileTabTitle" v-if="mobileFlag">
                                    <div class="mobileTabTitleTxt">{{ $t('MyFollowers_PL1') }}</div>
                                    <div class="mobileTabTitleData">{{curDayPorfit}}</div>
                                </div>
                                <el-select v-if="mobileFlag" v-model="duationType" placeholder="" class="echartsTimeSheet" @change="getEchartData">
                                    <el-option key="0" :label="$t('echarts_time1')" value="0">
                                    </el-option>
                                    <el-option key="1" :label="$t('echarts_time2')" value="1">
                                    </el-option>
                                    <el-option key="2" :label="$t('echarts_time3')" value="2">
                                    </el-option>
                                </el-select>
                                <div class="EquityEchart" ref="PlEchart" :style="{ width: '100%', height: '300px' }"></div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                    <div class="echarts_closeOrderRight echarts_top longTabs">
                            <el-tabs v-model="closeActiveName" @tab-click="getCloseEcharts">
                                <el-tab-pane :label="$t('MyFollowers_Lot')" name="1">
                                    <div class="EquityEchart" ref="CloseLotEchart" :style="{ width: '100%', height: '300px' }">
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane :label="$t('MyFollowers_PL')" name="2">
                                    <div class="EquityEchart" ref="ClosePlEchart" :style="{ width: '100%', height: '300px' }">
                                    </div>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                </div>

                <div class="echarts_table tableDiv tabletoptotal">
                    <el-tabs v-model="tableActiveName" @tab-click="handleTwoTable()">
                        <el-tab-pane :label="$t('echarts_workOrder')" name="0">
                            <el-table v-if="!mobileFlag" :data="historyPositionTable" border :summary-method="getSummariesOrder" show-summary >
                                <el-table-column width="80" v-if="!mobileFlag" prop="sortNum" :label="$t('MyFollowers_Number')">
                                </el-table-column>
                                <el-table-column prop="ticket" :label="$t('ManualReplenishment_MTOrder')">
                                </el-table-column>
                                <el-table-column prop="symbol" :label="$t('echarts_symboltype')">
                                </el-table-column>
                                <el-table-column prop="open_price" :label="$t('ManualReplenishment_Price')">
                                </el-table-column>
                                <!-- <el-table-column prop="close_price" :label="$t('ManualReplenishment_Closeprice')">
                                    <template slot-scope="scope">
                                        <div>
                                            {{ '——' }}
                                        </div>
                                    </template>
                                </el-table-column> -->
                                <el-table-column prop="profit" :label="$t('MyFollowers_PL')">
                                    <template slot-scope="scope">
                                        <div :class="scope.row.profit > 0?'greenNum':'redNum'">
                                        {{ scope.row.profit ? formatNumberFractionDigits(scope.row.profit) : 0 }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="lots" :label="$t('ManualReplenishment_Size')">
                                    <template slot-scope="scope">
                                        <div>
                                            {{ scope.row.lots ? scope.row.lots+' lots' : '0.00 lots' }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="open_time"  width="160" :label="$t('ManualReplenishment_openTime')">
                                </el-table-column>
                                <!-- <el-table-column prop="close_time"  width="160" :label="$t('ManualReplenishment_Closetime')">
                                    <template slot-scope="scope">
                                        <div>
                                            {{ '——' }}
                                        </div>
                                    </template>
                                </el-table-column> -->
                                <el-table-column prop="commission" :label="$t('ManualReplenishment_Commission')">
                                    <template slot-scope="scope">
                                        <div>
                                            {{ scope.row.commission ? formatNumberFractionDigits(scope.row.commission) : '0.00' }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="swaps" :label="$t('echarts_nightmoney')">
                                    <template slot-scope="scope">
                                        <div>
                                            {{ scope.row.swaps ? formatNumberFractionDigits(scope.row.swaps) : '0.00' }}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- <div class="echarts_tableTotalProfit">
                                <span>{{ $t('total_profit') }}</span>
                                <b>
                                    +25555
                                </b>
                            </div>
                            <div class="echarts_tableTotalProfit">
                                <span>{{ $t('total_profit') }}</span>
                                <b>
                                    -25888
                                </b>
                            </div> -->
                            <div class="mobileTableList" v-show="mobileFlag">
                                <el-collapse accordion @change="collapseChange">
                                    <el-collapse-item v-for="(item,index) in historyPositionTable" :key="index">
                                    <template slot="title">
                                    <div class="mobileTableCard" @click.stop="showCollapse()">
                                        <div class="mobileTableItem">
                                        <p class="mobileTableTitle" >
                                            {{ $t('ManualReplenishment_MTOrder') }}
                                        </p>
                                        <p class="mobileTableContent" >

                                            {{ item.ticket }}
                                        </p>
                                        </div>
                                        <div class="mobileTableItem">
                                        <p class="mobileTableTitle" >
                                            {{ $t('echarts_symboltype') }}
                                        </p>
                                        <p class="mobileTableContent" >
                                            {{  item.symbol  }}
                                        </p>
                                        </div>
                                        <div class="mobileTableItem">
                                        <p class="mobileTableTitle" >
                                            {{ $t('ManualReplenishment_Price') }}
                                        </p>
                                        <p class="mobileTableContent" >
                                            {{  item.open_price }}
                                        </p>
                                        </div>
                                        <div class="mobileTableItem">
                                        <p class="mobileTableTitle" >
                                            {{ $t('MyFollowers_PL') }}
                                        </p>
                                        <p  :class="[item.profit > 0?'greenNum':'redNum','mobileTableContent']">
                                            {{  item.profit ? formatNumberFractionDigits(item.profit)  : 0 }}
                                        </p>
                                        </div>
                                    </div>

                                    </template>
                                    <div class="mobileTableCard" >
                                        <div class="mobileTableItem">
                                        <p class="mobileTableTitle" >
                                            {{ $t('ManualReplenishment_Size') }}
                                        </p>
                                        <p class="mobileTableContent" >
                                            {{ item.lots ? item.lots+' lots' : '0.00 lots' }}
                                        </p>
                                        </div>
                                        <div class="mobileTableItem">
                                        <p class="mobileTableTitle" >
                                            {{ $t('ManualReplenishment_openTime') }}
                                        </p>
                                        <p class="mobileTableContent" >
                                            {{  item.open_time }}
                                        </p>
                                        </div>
                                        <div class="mobileTableItem">
                                        <p class="mobileTableTitle" >
                                            {{ $t('ManualReplenishment_Commission') }}
                                        </p>
                                        <p class="mobileTableContent" >
                                            {{  item.commission? formatNumberFractionDigits(item.commission):'0.00' }}
                                        </p>
                                        </div>
                                        <div class="mobileTableItem">
                                        <p class="mobileTableTitle" >
                                            {{ $t('echarts_nightmoney') }}
                                        </p>
                                        <p  class="mobileTableContent">
                                            {{  item.swaps? formatNumberFractionDigits(item.swaps) :'0.00' }}
                                        </p>
                                        </div>

                                    </div>
                                </el-collapse-item>

                                </el-collapse>
                                <div class="x" v-if="up && historyPositionTable.length < total">
                                    <i  class="el-icon-loading "></i>
                                    {{ $t('loading') }}
                                    </div>
                                    <div class="x" v-if="!up && historyPositionTable.length >= total">

                                    {{ $t('noMoreData') }}
                                    </div>
                            </div>
                            <el-pagination class="tablePage" :prev-text="$t('MyFollowers_PreviousPage')" :next-text="$t('MyFollowers_NexPage')"
                                :current-page="currentPage" @current-change="handleCurrentChange" :page-size="pageSize"
                                @size-change="handleSizeChange" :page-sizes="[5, 10, 20, 50, 100]"
                                layout="prev, pager, next, sizes" :total="total">
                            </el-pagination>
                        </el-tab-pane>
                        <el-tab-pane :label="$t('echarts_pingOrder')" name="1">
                            <el-table :data="historyPositionTable" v-if="!mobileFlag" border :summary-method="getSummariesOrder" show-summary>
                                <el-table-column width="80" v-if="!mobileFlag" prop="sortNum" :label="$t('MyFollowers_Number')">
                                </el-table-column>
                                <!-- <el-table-column prop="goodsName" :label="$t('echarts_people')">
                                </el-table-column>
                                <el-table-column prop="volume" :label="$t('echarts_symbol')">
                                </el-table-column> -->
                                <el-table-column prop="ticket" :label="$t('ManualReplenishment_MTOrder')">
                                </el-table-column>
                                <el-table-column prop="symbol" :label="$t('echarts_symboltype')">
                                </el-table-column>
                                <el-table-column prop="open_price" :label="$t('ManualReplenishment_Price')">
                                </el-table-column>
                                <el-table-column prop="close_price" :label="$t('ManualReplenishment_Closeprice')">

                                </el-table-column>
                                <el-table-column prop="profit" :label="$t('MyFollowers_PL')">
                                    <template slot-scope="scope">
                                        <div :class="scope.row.profit > 0?'greenNum':'redNum'">
                                        {{ scope.row.profit ? formatNumberFractionDigits(scope.row.profit) : 0 }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="lots" :label="$t('ManualReplenishment_Size')">
                                    <template slot-scope="scope">
                                        <div>
                                            {{ scope.row.lots ? scope.row.lots+' lots' : '0.00 lots' }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="open_time"  width="160" :label="$t('ManualReplenishment_openTime')">
                                </el-table-column>
                                <el-table-column prop="close_time" width="160" :label="$t('ManualReplenishment_Closetime')">
                                </el-table-column>
                                <el-table-column prop="commission" :label="$t('ManualReplenishment_Commission')">
                                    <template slot-scope="scope">
                                        <div>
                                            {{ scope.row.commission ?formatNumberFractionDigits(scope.row.commission)  : '0.00' }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="swaps" :label="$t('echarts_nightmoney')">
                                    <template slot-scope="scope">
                                        <div>
                                            {{ scope.row.swaps ?formatNumberFractionDigits(scope.row.swaps)  : '0.00' }}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- <div class="echarts_tableTotalProfit">
                            <span>{{ $t('total_profit') }}</span> +25555
                            </div>
                            <div class="echarts_tableTotalProfit">
                                -25888
                            </div> -->
                            <div class="mobileTableList" v-show="mobileFlag">
                                <el-collapse accordion @change="collapseChange">
                                    <el-collapse-item v-for="(item,index) in historyPositionTable" :key="index">
                                        <template slot="title">
                                            <div class="mobileTableCard" @click.stop="showCollapse()">
                                                <div class="mobileTableItem">
                                                <p class="mobileTableTitle" >
                                                    {{ $t('ManualReplenishment_MTOrder') }}
                                                </p>
                                                <p class="mobileTableContent" >
                                                    {{ item.ticket }}
                                                </p>
                                                </div>
                                                <div class="mobileTableItem">
                                                <p class="mobileTableTitle" >
                                                    {{ $t('echarts_symboltype') }}
                                                </p>
                                                <p class="mobileTableContent" >
                                                    {{  item.symbol  }}
                                                </p>
                                                </div>
                                                <div class="mobileTableItem">
                                                <p class="mobileTableTitle" >
                                                    {{ $t('ManualReplenishment_Price') }}
                                                </p>
                                                <p class="mobileTableContent" >
                                                    {{  item.open_price ? item.open_price : 0 }}
                                                </p>
                                                </div>
                                                <div class="mobileTableItem">
                                                <p class="mobileTableTitle" >
                                                    {{ $t('ManualReplenishment_Closeprice') }}
                                                </p>
                                                <p  class="mobileTableContent">
                                                    {{  item.close_price ? item.close_price : 0 }}
                                                </p>
                                                </div>
                                            </div>
                                        </template>
                                        <div class="mobileTableCard" >
                                            <div class="mobileTableItem">
                                                <p class="mobileTableTitle" >
                                                    {{ $t('MyFollowers_PL') }}
                                                </p>
                                                <p  :class="[item.profit > 0?'greenNum':'redNum','mobileTableContent']">
                                                    {{  item.profit ? item.profit : 0 }}
                                                </p>
                                            </div>
                                            <div class="mobileTableItem">
                                                <p class="mobileTableTitle" >
                                                    {{ $t('ManualReplenishment_Size') }}
                                                </p>
                                                <p class="mobileTableContent" >
                                                    {{ item.lots ? item.lots+' lots' : '0.00 lots' }}
                                                </p>
                                            </div>
                                            <div class="mobileTableItem">
                                                <p class="mobileTableTitle" >
                                                    {{ $t('ManualReplenishment_openTime') }}
                                                </p>
                                                <p class="mobileTableContent" >
                                                    {{  item.open_time  }}
                                                </p>
                                            </div>
                                            <div class="mobileTableItem">
                                                <p class="mobileTableTitle" >
                                                    {{ $t('ManualReplenishment_Closetime') }}
                                                </p>
                                                <p  class="mobileTableContent">
                                                    {{  item.close_time  }}
                                                </p>
                                            </div>
                                            <div class="mobileTableItem">
                                                <p class="mobileTableTitle" >
                                                    {{ $t('ManualReplenishment_Commission') }}
                                                </p>
                                                <p  class="mobileTableContent">
                                                    {{  item.commission?formatNumberFractionDigits(item.commission):'0.00' }}
                                                </p>
                                            </div>
                                            <div class="mobileTableItem">
                                                <p class="mobileTableTitle" >
                                                    {{ $t('echarts_nightmoney') }}
                                                </p>
                                                <p  class="mobileTableContent">
                                                    {{  item.swaps?formatNumberFractionDigits(item.swaps):'0.00' }}
                                                </p>
                                            </div>
                                        </div>
                                    </el-collapse-item>

                                </el-collapse>
                                <div class="x" v-if="up && historyPositionTable.length < total">
                                <i  class="el-icon-loading "></i>
                                {{ $t('loading') }}
                                </div>
                                <div class="x" v-if="!up && historyPositionTable.length >= total">

                                {{ $t('noMoreData') }}
                                </div>
                            </div>
                            <el-pagination class="tablePage" :prev-text="$t('MyFollowers_PreviousPage')" :next-text="$t('MyFollowers_NexPage')"
                                :current-page="currentPage" @current-change="handleCurrentChange" :page-size="pageSize"
                                @size-change="handleSizeChange" :page-sizes="[5, 10, 20, 50, 100]"
                                :pager-count="5"
                                layout="prev, pager, next, sizes" :total="total">
                            </el-pagination>
                        </el-tab-pane>
                    </el-tabs>
                    <!-- <infinite-loading @infinite="loadMore" /> -->
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import { concatParams, concatTableData } from "../../plugins/common.js";
import { debounce } from 'lodash';
import BScroll from '@better-scroll/core'
  import Pullup from '@better-scroll/pull-up'
  import Bus from '../../plugins/bus'
  import { formatNumberFractionDigits } from "@/shared/utils/formatNumber.js";

  BScroll.use(Pullup)


export default {
    name: 'FollowEcharts',
    components: {
    },
    computed: {
        ...mapState(["checkPortfolio", "activeTab", "mobileFlag"]),
    },
    data() {
        return {
            tableData: [],
            currentPage: 1,
            total: 0,
            pageSize: 5,
            tableSearchForm: {
                Account: '',
                DateRange: ''
            },
            curDayEquity: 0,
            curDayPorfit: 0,
            curDayVolume: 0,
            activeName: '0',
            workActiveName: "1",
            closeActiveName: "1",
            timeSheet: '',
            duationType: "0",
            myChart: {},
            xData: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"], //横坐标
            yData: [23, 24, 18, 25, 27, 28, 25], //人数数据
            goodsArr: [
                {
                    goods: this.$t('MyFollowers_Fx'),
                    goodsTag: 'fx'
                },
                {
                    goods: this.$t('MyFollowers_Energy'),
                    goodsTag: 'energy'
                },
                {
                    goods: this.$t('MyFollowers_Metal'),
                    goodsTag: 'metal'
                },
                {
                    goods: this.$t('MyFollowers_Indices'),
                    goodsTag: 'cfd'
                },
                {
                    goods: this.$t('MyFollowers_Crypto'),
                    goodsTag: 'crypto'
                },
            ],
            historyTable: [],
            positionTable: [],
            tableActiveName: "0",
            historyPositionTable: [],
            currentPage: 1,
            total: 0,
            pageSize: 5,
            up:false,
            firstOpen:false
        }
    },
    mounted() {
        Bus.$on('isLanguage', myMsg => {
            this.getEchartData()
            this.getCloseEcharts()
        });
        this.getEchartData()
        this.getCloseEcharts()
        this.getTwoTable(this.currentPage, this.pageSize)
    },
    created() {
    },
    methods: {
        formatNumberFractionDigits,
        collapseChange(){
            this.$nextTick(() => {
                    if(!this.firstOpen){
                    this.scroll.maxScrollY = this.scroll.maxScrollY - 200
                    this.firstOpen = true
                    }else{
                    this.scroll.maxScrollY = this.scroll.maxScrollY + 200
                    this.firstOpen = false
                    }
                });

        },
        getEchartData() {
            var params = {
                account: this.$route.query.account,
                dataType: this.activeName,
                durationType: this.duationType,
            };
            this.$axios
                .get("/Api/invite/AccountLineData" + concatParams(params))
                .then((res) => {
                    let dt = res.data;
                    if (dt.isSuccess) {
                        var that = this
                        that.curDayEquity = dt.result.curDayEquity
                        that.curDayVolume = dt.result.curDayVolume
                        that.curDayPorfit = dt.result.curDayPorfit
                        var yTxt = ''
                        if(that.activeName == 0 && that.duationType == 0){
                            yTxt=that.$t('MyEcharts_todayequity')
                        }else if(that.activeName == 1 && that.duationType == 0){
                            yTxt=that.$t('MyEcharts_todayvolumn')
                        }else if(that.activeName == 2 && that.duationType == 0){
                            yTxt=that.$t('MyEcharts_todaypl')
                        }else if(that.activeName == 0 && that.duationType == 1){
                            yTxt=that.$t('MyEcharts_weekequity')
                        }else if(that.activeName == 1 && that.duationType == 1){
                            yTxt=that.$t('MyEcharts_weekvolumn')
                        }else if(that.activeName == 2 && that.duationType == 1){
                            yTxt=that.$t('MyEcharts_weekpl')
                        }else if(that.activeName == 0 && that.duationType == 2){
                            yTxt=that.$t('MyEcharts_monthequity')
                        }else if(that.activeName == 1 && that.duationType == 2){
                            yTxt=that.$t('MyEcharts_monthvolumn')
                        }else if(that.activeName == 2 && that.duationType == 2){
                            yTxt=that.$t('MyEcharts_monthpl')
                        }
                        const option = {
                            xAxis: {
                                data: dt.result.x_date
                            },
                            grid: {
                                left: "1%",
                                containLabel: true
                            },
                            yAxis: {},
                            tooltip: {
                                trigger: 'axis',
                                formatter: function (params) {
                                    let str = '';
                                    params.forEach((item, idx) => {
                                        str += `${yTxt} <br/> ${item.marker}${item.data} ${that.activeName == 1?'Lots':'USD'}`

                                    })
                                    return str
                                }
                            },
                            series: [
                                {
                                    data: dt.result.y_data,
                                    type: "line", // 类型设置为折线图
                                    smooth: true,
                                    symbolSize: 8,
                                    itemStyle: {
                                        // color: '#4D71FF',
                                        // shadowColor: 'rgba(236, 105, 26, 0.30)',
                                        // shadowBlur: 20,
                                    },
                                    lineStyle: {
                                        // shadowColor: "rgba(77, 113, 255, 0.30)",
                                        // shadowOffsetX: 0,
                                        // shadowOffsetY: 11,
                                        // opacity: 1,
                                        // shadowBlur: 18,
                                        // type: "solid",
                                        // width: 2
                                        normal: {
                                            width: 2,
                                            shadowColor: 'rgba(77, 113, 255, 0.30)',
                                            shadowBlur: 18,
                                            shadowOffsetY: -11
                                        }
                                    },
                                }
                            ]
                        };
                        var myChart = "";
                        if (that.activeName == 0) {
                            // 图标初始化
                            myChart = that.$echarts.init(that.$refs.EquityEchart)
                        } else if (that.activeName == 1) {
                            myChart = that.$echarts.init(that.$refs.LotEchart)// 图标初始化
                        } else {
                            myChart = that.$echarts.init(that.$refs.PlEchart)
                        }
                        myChart.setOption(option);// 渲染页面
                        //随着屏幕大小调节图表
                        window.addEventListener("resize", () => {
                            myChart.resize();
                        });
                        that.$on('hook:destroyed', () => {
                            window.removeEventListener("resize", function () {
                                myChart.resize();
                            });
                        })
                        // console.log(this.tableData)
                    }
                });

        },
        getCloseEcharts() {
            var params = {
                account: this.$route.query.account,
            };
            this.$axios
                .get("/Api/invite/AccountBarData" + concatParams(params))
                .then((res) => {
                    let dt = res.data;
                    if (dt.isSuccess) {
                        this.getTable(dt.result.accountData.historyData.table)
                        const CloseLotEchartoption = {
                            xAxis: {
                                data: dt.result.accountData.historyData.barData.symbol
                            },
                            yAxis: {
                                minInterval: 1
                            },
                            grid: {
                                left: "1%",
                                containLabel: true
                            },
                            tooltip: {
                                trigger: 'axis',
                                formatter: function (params) {
                                    let str = '';
                                    params.forEach((item, idx) => {
                                        str += `${item.name} <br/> ${item.marker}${item.data} ${'Lots'}`

                                    })
                                    return str
                                }
                            },
                            series: [
                                {
                                    data: dt.result.accountData.historyData.barData.volume,
                                    type: "bar",
                                    smooth: true,
                                    barWidth:27,
                                    itemStyle: {
                                        normal: {
                                            label: {
                                                show: true, //开启显示
                                                position: 'top', //在上方显示
                                                textStyle: { //数值样式
                                                    color: 'black',
                                                    fontSize: 12
                                                }
                                            },
                                            color: function(params) {
                        // 给出颜色组
                                                var colorList = ['#4D71FF','#8ADCED','#4D71FF','#8ADCED','#4D71FF',];
                                                return colorList[params.dataIndex]
                                            },
                                        }
                                    },
                                }
                            ]
                        };
                        const ClosePlEchartoption = {
                            xAxis: {
                                data: dt.result.accountData.historyData.barData.symbol
                            },
                            yAxis: {
                                minInterval: 1
                            },
                            tooltip: {
                                trigger: 'axis',
                                formatter: function (params) {
                                    let str = '';
                                    params.forEach((item, idx) => {
                                        str += `${item.name} <br/> ${item.marker}${item.data} ${'USD'}`

                                    })
                                    return str
                                }
                            },
                            series: [
                                {
                                    data: dt.result.accountData.historyData.barData.profit,
                                    type: "bar",
                                    smooth: true,
                                    barWidth:27,
                                    itemStyle: {
                                        normal: {
                                            label: {
                                                show: true, //开启显示
                                                position: 'top', //在上方显示
                                                textStyle: { //数值样式
                                                    color: 'black',
                                                    fontSize: 12
                                                }
                                            },
                                            color: function(params) {
                        // 给出颜色组
                                                var colorList = ['#4D71FF','#8ADCED','#4D71FF','#8ADCED','#4D71FF',];
                                                return colorList[params.dataIndex]
                                            },
                                        }
                                    },
                                }
                            ]
                        };
                        // const WorkLotEchartoption = {
                        //     xAxis: {
                        //         data: dt.result.accountData.positionData.barData.symbol
                        //     },
                        //     tooltip: {
                        //         trigger: 'axis',
                        //         formatter: function (params) {
                        //             let str = '';
                        //             params.forEach((item, idx) => {
                        //                 str += `${item.name} <br/> ${item.marker}${item.data} ${'Lots'}`

                        //             })
                        //             return str
                        //         }
                        //     },
                        //     yAxis: {
                        //         minInterval: 1
                        //     },
                        //     series: [
                        //         {
                        //             data: dt.result.accountData.positionData.barData.volume,
                        //             type: "bar",
                        //             smooth: true,
                        //             barWidth:27,
                        //             color:'#EDB1B0'
                        //         }
                        //     ]
                        // };
                        // const WorkNightEchartoption = {
                        //     xAxis: {
                        //         data: dt.result.accountData.positionData.barData.symbol
                        //     },
                        //     tooltip: {
                        //         trigger: 'axis',
                        //         formatter: function (params) {
                        //             let str = '';
                        //             params.forEach((item, idx) => {
                        //                 str += `${item.name} <br/> ${item.marker}${item.data} ${'USD'}`

                        //             })
                        //             return str
                        //         }
                        //     },
                        //     yAxis: {
                        //         minInterval: 1
                        //     },
                        //     series: [
                        //         {
                        //             data: dt.result.accountData.positionData.barData.fee,
                        //             type: "bar",
                        //             smooth: true,
                        //             barWidth:27,
                        //             color:'#EDB1B0'
                        //         }
                        //     ]
                        // };
                        // const WorkPlEchartoption = {
                        //     xAxis: {
                        //         data: dt.result.accountData.positionData.barData.symbol
                        //     },
                        //     yAxis: {
                        //         minInterval: 1
                        //     },
                        //     tooltip: {
                        //         trigger: 'axis',
                        //         formatter: function (params) {
                        //             let str = '';
                        //             params.forEach((item, idx) => {
                        //                 str += `${item.name} <br/> ${item.marker}${item.data} ${'USD'}`

                        //             })
                        //             return str
                        //         }
                        //     },
                        //     series: [
                        //         {
                        //             data: dt.result.accountData.positionData.barData.profit,
                        //             type: "bar",
                        //             smooth: true,
                        //             barWidth:27,
                        //             color:'#EDB1B0'
                        //         }
                        //     ]
                        // };
                        var CloseEchart
                        var WorkEchart
                        if (CloseEchart !== null && CloseEchart !== '' && CloseEchart !== undefined) {
                            // 清除实例中的组件和图表
                            CloseEchart.clear()

                            // 销毁实例，销毁后实例无法再被使用（适用于有tab标签时）
                            CloseEchart.dispose()
                        }
                        // if (WorkEchart !== null && WorkEchart !== '' && WorkEchart !== undefined) {
                        //     // 清除实例中的组件和图表
                        //     WorkEchart.clear()

                        //     // 销毁实例，销毁后实例无法再被使用（适用于有tab标签时）
                        //     WorkEchart.dispose()
                        // }
                        if (this.closeActiveName == 1) {
                            // 图标初始化
                            CloseEchart = this.$echarts.init(this.$refs.CloseLotEchart)
                            CloseEchart.setOption(CloseLotEchartoption);

                        } else if (this.closeActiveName == 2) {
                            CloseEchart = this.$echarts.init(this.$refs.ClosePlEchart)// 图标初始化
                            CloseEchart.setOption(ClosePlEchartoption);

                        }

                        // if (this.workActiveName == 1) {
                        //     // 图标初始化
                        //     WorkEchart = this.$echarts.init(this.$refs.WorkLotEchart)
                        //     WorkEchart.setOption(WorkLotEchartoption);
                        // } else if (this.workActiveName == 2) {
                        //     WorkEchart = this.$echarts.init(this.$refs.WorkNightEchart)// 图标初始化
                        //     WorkEchart.setOption(WorkNightEchartoption);

                        // } else if (this.workActiveName == 3) {
                        //     WorkEchart = this.$echarts.init(this.$refs.WorkPlEchart)// 图标初始化
                        //     WorkEchart.setOption(WorkPlEchartoption);

                        // }

                        //随着屏幕大小调节图表
                        window.addEventListener("resize", () => {
                            CloseEchart.resize();
                            WorkEchart.resize();

                        });
                        this.$on('hook:destroyed', () => {
                            window.removeEventListener("resize", function () {
                                CloseEchart.resize();
                                WorkEchart.resize();
                            });
                        })
                        // console.log(this.tableData)
                    }
                });
        },
        handleClickRight() {
            this.$nextTick(() => {
                this.getCloseEcharts();
            });

        },
        getTable(historyData, positionData) {
            // console.log(this.goodsArr)
            this.historyTable = []
            this.positionTable = []
            this.goodsArr.forEach((item, index) => {
                var historyObj = {}
                var positionObj = {}
                for (var i in historyData) {
                    // console.log(i)
                    if (i.match(item.goodsTag)) {
                        historyObj.goodsName = item.goods
                        historyObj.volume = historyData[item.goodsTag + 'Volume']
                        historyObj.profit = historyData[item.goodsTag + 'Profit']
                    }

                }
                // for (var i in positionData) {
                //     // console.log(i)
                //     if (i.match(item.goodsTag)) {
                //         positionObj.goodsName = item.goods
                //         positionObj.volume = positionData[item.goodsTag + 'VolumePosition']
                //         positionObj.profit = positionData[item.goodsTag + 'ProfitPosition']
                //         positionObj.fee = positionData[item.goodsTag + 'FeePosition']
                //     }

                // }
                this.historyTable.push(historyObj)
                // this.positionTable.push(positionObj)
            })
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = this.$t('echarts_total');
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        } else {
                            return prev;
                        }
                    }, 0);
                    sums[index] =formatNumberFractionDigits(sums[index])
                } else {
                    sums[index] = '';
                }
            });

            return sums;

        },
        getSummariesOrder(param){
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = this.$t('echarts_total');
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (column.property === 'profit' || column.property === 'volume'|| column.property === 'commission'|| column.property === 'swaps') {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr)
                        if (!isNaN(value)) {
                            return prev + curr
                        } else {
                            return prev
                        }
                    }, 0)

                    sums[index] = formatNumberFractionDigits(sums[index])
                    if(column.property === 'volume'){
                        sums[index] = formatNumberFractionDigits(sums[index]/100)
                    }
                } else {
                    sums[index] = ' '
                }
            });

            return sums;
        },
        handleTwoTable(){
            this.currentPage = 1
            this.historyPositionTable = []
            this.firstOpen = false
            this.getTwoTable(this.currentPage,this.pageSize)
        },
        scrollFn(){
            this.$nextTick(() => {

                if (!this.scroll) {
                    this.scroll = new BScroll(this.$refs.bscroll, {
                        click: true,
                        scrollY: true,
                        probeType: 3
                    });
                }else{
                    this.scroll.refresh();
                }

                //touchEnd（手指离开以后触发） 通过这个方法来监听下拉刷新
                this.scroll.on('touchEnd', (pos) => {
                    //上拉加载 总高度>下拉的高度+10 触发加载更多
                    if(this.scroll.maxScrollY>pos.y + 50){
                        // console.log("加载更多")
                        this.up=true;
                        setTimeout(()=>{
                        //使用refresh 方法 来更新scroll  解决无法滚动的问题
                            if(this.historyPositionTable.length < this.total){
                                this.currentPage = this.currentPage + 1
                                this.getTwoTable(this.currentPage,this.pageSize,'noRefresh')
                                this.scroll.refresh();
                                this.up=false;
                            }else{
                                this.up=false;
                            }

                    },1000)

                    }
                    // console.log(this.scroll.maxScrollY+"总距离----下拉的距离"+pos.y)
                })

                // console.log(this.scroll.maxScrollY)
            });
        },
        getTwoTable(pageIndex, pageSize,tag) {
            // debugger
            if(!this.mobileFlag || tag !== 'noRefresh'){
                this.historyPositionTable = [];
            }
            var params = {
                Account: this.$route.query.account,
                OrderType: this.tableActiveName,
                pageIndex: pageIndex,
                pageSize: pageSize,
            };
            this.$axios
                .get("/Api/invite/GetTradeOrder" + concatParams(params))
                .then((res) => {
                    let dt = res.data;
                    if (dt.isSuccess) {
                        this.total = dt.result.total;
                        if(this.mobileFlag && tag == 'noRefresh'){
                            dt.result.pageList.forEach((item,index) =>{
                                this.historyPositionTable.push(item)
                            })


                        }else{
                            this.historyPositionTable = concatTableData(
                                dt.result.pageList,
                                dt.result.total,
                                dt.result.pageIndex,
                                this.pageSize
                            );
                        }
                        if(this.mobileFlag){
                            this.scrollFn()
                        }
                        // console.log(this.historyPositionTable)
                    }
                });
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTwoTable(this.currentPage, this.pageSize);
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.getTwoTable(this.currentPage, this.pageSize);
        },
        goBack(){
            this.$router.go(-1)
        },
    }
}
</script>
<style lang="less" scoped>
.FollowEcharts {
    position: relative;
    .refreshListBtnDiv{
        position: absolute;
        right: 40px;
        top: 30px;
    }

    .PageCommon {
        padding-top: 0;
        .echarts_top {
            background: #ffffff;
        }

    }

    .signalTitleTips {
        font-size: 12px;
        color: #8c8c8c;
    }

    .echarts_top {
        position: relative;

    }

    .echartsTimeSheet {
        position: absolute;
        right: 16px;
        z-index: 99;
        top: 6px;
    }

    .echarts_orderTitle {
        color: #212121;
        font-family: 'PingFangSC-Medium';
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 40px;
    }

    .echarts_closeOrderContent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .echarts_closeOrderLeft,
        .echarts_closeOrderRight {
            width: 48%;
        }

        .echarts_closeOrderLeft {}
    }

    .echarts_table {
        margin-top: 40px;
    }
    .tabletoptotal{
        background: transparent;
        padding: 0;
    }
}
@media screen and (max-width:767px){
    .FollowEcharts{
        height: 100%;
        .refreshListBtnDiv{
            position: relative;
            right: 16px;
            top: 0;
            text-align: right;
        }
        .echarts_closeOrderContent{
            flex-direction: column;
            padding-top: 0;
            .echarts_closeOrderLeft{
                flex-direction: column;
                width: 100%;
                .echartsTimeSheet{
                    position: relative;
                    top: 0;
                    right: 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    width: calc(100% - 20px) ;
                    margin: 0 auto;
                    .el-input__inner{
                        max-width: 100%;
                    }
                }
            }
            .echarts_top{
                background: transparent;
            }
            .echarts_closeOrderRight{
                width: 100%;
            }
        }
    }
}


</style>
